import "./index.css";
import {DeleteTwoTone, PlusSquareTwoTone, RollbackOutlined} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../store/index";
import { selectedStatusIsProcessing } from "../../store/engineResultSlice";
import { notification } from "antd";
import { STATUS_ROOM } from "../../const/RoomConst";
import {unsetTarget, updateRoomRevertUnMap, updateRoomToUnmapped} from "../../store/roomSlice";

interface IProps {
  record: any;
  visible: boolean;
  x: number;
  y: number;
  openListTargetRoomMapping: () => void
}
function RoomContext(props: IProps) {
  const dispatch = useAppDispatch();
  const { record, visible, x, y, openListTargetRoomMapping } = props;
  const isUnmapped = (record.status === STATUS_ROOM.UNMMAPED) || record.targetRoomId !== null;
  const isRevert = record.status === STATUS_ROOM.UNMMAPED;
  const isProcessing = useAppSelector(selectedStatusIsProcessing);

  function unmappedRoom() {
    if (isProcessing) {
      notification.warn({
        message: "Please complete the mapping process before doing other tasks un-map Room",
        placement: "top",
      });
      return;
    }
    const confirm = window.confirm("Are you sure to un-map this room?");
    if (confirm) {
      dispatch(updateRoomToUnmapped({
        roomIds: [record.id]
      }));
    }
  }

  function revertRoom() {
    if (isProcessing) {
      notification.warn({
        message:
          "Please complete the mapping process before doing other tasks revert",
        placement: "top",
      });
      return;
    }

    const confirm = window.confirm("Are you sure to revert this room?");
    if (confirm) {
      dispatch(updateRoomRevertUnMap(record.id));
    }
    
  }

  function revertTargetRoom() {
    dispatch(unsetTarget(record.id));
  }

  return (
    <>
      {visible && (
        <ul className="popup" style={{ left: `${x}px`, top: `${y}px` }}>
          {
            !isUnmapped && (
              <li onClick={unmappedRoom}>
                <DeleteTwoTone /> Un-map
              </li>
            )
          }
          {
            isRevert && (
              <li onClick={revertRoom}>
                <RollbackOutlined /> Revert
              </li>
            )
          }
          {
            record.status !== STATUS_ROOM.STATUS_MANUAL_MAPPING && (
              <li onClick={() => openListTargetRoomMapping()}>
                <PlusSquareTwoTone/> Mapping Target Room
              </li>
            )
          }
          {
            record.status === STATUS_ROOM.STATUS_MANUAL_MAPPING && (
              <li onClick={revertTargetRoom}>
                <RollbackOutlined /> Revert Mapping Target Room
              </li>
            )
          }

        </ul>
      )}
    </>
  );
}

export default RoomContext;
