const URL = {
  Login: "/login",
  Home: "/",
  TargetRoomManagement: "/target-room-management/:hotelId",
  RoomManagement: "/room-management/:hotelId",
  RuleManagement: "/rule-management/:hotelId",
  MappingResult: "/mapping-result/:hotelId",
  RedirectedLogin: "/login-redirected",
  InsertTargetRoom: "/target-room-management/insertTargetRoom"
};

export default URL;
