interface IDictionary {
    [index: string]: any;
}

export const RULE_TARGET = {
    GENERAL_RULE: 0,
}

export const RULE_MANAGEMENT_DIMENSIONS = [
    'GRADE',
    'VIEW',
    'BED',
    'BUNDLE',
    'EXTRA',
    'IGNORE',
    'LOCATION',
    'HOTEL_FACILITY',
    'MEAL',
    'ROOM_FACILITY',
    'SERVICE',
    'MIXED',
]

export const DIMENSIONS = [
    'GRADE',
    'VIEW',
    'BED',
    'BUNDLE',
    'EXTRA',
    'IGNORE',
    'LOCATION',
    'HOTEL_FACILITY',
    'MEAL',
    'ROOM_FACILITY',
    'SERVICE',
]
export const DIMENSIONS_PREPROCESS = [
    'GRADE',
    'VIEW',
    'BED',
    'BUNDLE',
    'EXTRA',
    'IGNORE',
    'UNMAPPED',
    'LOCATION',
    'HOTEL_FACILITY',
    'MEAL',
    'ROOM_FACILITY',
    'SERVICE',
]

export const DIMENSIONS_SCORE = {
    'GRADE': 7,
    'VIEW': 5,
    'BED': 6,
    'BUNDLE': 3,
    'EXTRA': 3,
    'LOCATION': 3,
    'HOTEL_FACILITY': 3,
    'MEAL': 3,
    'ROOM_FACILITY': 3,
    'SERVICE': 3,
    'IGNORE': 0,
} as IDictionary

export const ABBREVIATIONS = {
    'GRADE': 'G',
    'VIEW': 'V',
    'BED': 'B'
} as IDictionary

export const MANDATORY_DIMENSIONS = [
    'GRADE',
    'VIEW',
    'BED'
]