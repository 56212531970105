export const ERROR_MESSAGE = 'An error has occurred';
export const LIMIT_PAGESIZE_DEFAULT = 100;
export const ID_TARGET_ROOM_NOT_MAPPED = 99999;
export const NATIONS = [
    {
        value: 'kr',
        label: 'Korea',
    },
    {
        value: 'vn',
        label: 'VietNamese',
    },
    {
        value: 'jp',
        label: 'Japanese'
    },
    {
        value: 'th',
        label: 'Thailand',
    },
]