import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {get} from "lodash";
import {RootState} from "./index";
import request from "../util/request";

export interface IParamsGetRoomsByIds {
  roomIds: number[];
}

export interface IParamSetTargetRoom {
  roomId: number;
  targetRoomId: number;
}

export interface IInitialState {
  listRoomsHotel: [],
  listRoomsByIds: any,
  loadingListRoomById: boolean,
  unmappedRoom: boolean,
  revertUnMapRoom: boolean,
  currentRecordActionRoom: any,
  setTarget: boolean,
  unsetTarget: boolean,
  isSimulate:boolean,
  currentPage: number

}

const initialState: IInitialState = {
  listRoomsHotel: [],
  listRoomsByIds: [],
  loadingListRoomById: false,
  unmappedRoom: false,
  revertUnMapRoom: false,
  currentRecordActionRoom: null,
  setTarget: false,
  unsetTarget: false,
  isSimulate:false,
  currentPage: 1,
};

export const getRoomsHotel = createAsyncThunk(
  "room/getRoomsHotel",
  async (hotelId:number, { rejectWithValue }) => {
    try {
      return await request({
        url: `/room?size=3000&hotelId=${hotelId}`,
        method: "GET",
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRoomsByIds = createAsyncThunk(
  "room/getRoomsByIds",
  async (params: IParamsGetRoomsByIds, { rejectWithValue }) => {
    try {
      let promiseArray: any[] = [];
      const chunkSize = 80;
      for (let i = 0; i < params.roomIds.length; i += chunkSize) {
        const chunk = params.roomIds.slice(i, i + chunkSize);
        promiseArray.push(request({
          url: `/room/get-rooms-by-ids?roomIds=${Array.from(chunk).join(",")}`,
          method: "GET",
        }));
      }
      return await Promise.all(promiseArray);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRoomToUnmapped = createAsyncThunk(
  "room/updateRoomToUnmapped",
  async (params: IParamsGetRoomsByIds, { rejectWithValue }) => {
    try {
      return await request({
        url: `/room/unmapped?roomIds=${Array.from(params.roomIds).join(
          ","
        )}`,
        method: "PUT",
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRoomRevertUnMap = createAsyncThunk(
  "room/updateRoomRevertUnMap",
  async (hotelId: number, { rejectWithValue }) => {
    try {
      return await request({
        url: `/room/${hotelId}`,
        method: "PUT",
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setTarget = createAsyncThunk(
  "room/setTarget",
  async (params: IParamSetTargetRoom, { rejectWithValue }) => {
    try {
      return await request({
        url: `room/set-target`,
        method: "PUT",
        params,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const unsetTarget = createAsyncThunk(
  "room/unsetTarget",
  async (roomId: number, { rejectWithValue }) => {
    try {
      return await request({
        url: `room/unset-target?roomId=${roomId}`,
        method: "PUT",
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const insertTargetRoom = async (data: any) => {
    try {
      const result = await request({
        url: `target-room/multiple`,
        method: "POST",
        data
      });
      return result
    } catch (error) {
      console.error(error)
    }
  }

export const actionGetRoomsByIds = async (rules: string[]) => {
  const response = await request({
    url: "room/get-rooms-by-ids?roomIds=" + Array.from(rules).join(","),
    method: "GET",
  });
  return response;
}

export const slice = createSlice({
  name: "room",
  initialState,
  reducers: {
    resetListRoomsByTargetRoom(state) {
      state.listRoomsByIds = [];
    },
    actionResetStatusUnMap(state) {
      state.unmappedRoom = false;
    },
    actionResetStatusRevertUnMapRoom(state) {
      state.revertUnMapRoom = false;
    },
    actionSetCurrentRecordActionRoom(state, action) {
      state.currentRecordActionRoom = action.payload;
    },
    actionResetCurrentRecordActionRoom(state) {
      state.currentRecordActionRoom = null;
    },
    actionResetSetTarget(state) {
      state.setTarget = false;
    },
    actionResetUnSetTarget(state) {
      state.unsetTarget = false;
    },
    actionSetIsSimulate(state,action){
      state.isSimulate = action.payload;
    },
    actionSetCurrentPage(state,action){
      state.currentPage = action.payload;
    },

  },

  extraReducers: (builder) => {
    builder
      .addCase(getRoomsByIds.pending, (state, action) => {
        state.loadingListRoomById = true;
      })
      .addCase(getRoomsByIds.fulfilled, (state, action) => {
        state.loadingListRoomById = false;
        state.listRoomsByIds = action.payload.reduce((acc, item) => { return acc.concat(item.data); }, []);
      })
      .addCase(getRoomsByIds.rejected, (state, action) => {
        state.loadingListRoomById = false;
        state.listRoomsByIds = [];
      })
      .addCase(updateRoomToUnmapped.pending, (state) => {
        state.unmappedRoom = false;
      })
      .addCase(updateRoomToUnmapped.fulfilled, (state) => {
        state.unmappedRoom = true;
      })
      .addCase(updateRoomToUnmapped.rejected, (state) => {
        state.unmappedRoom = false;
      })
      .addCase(updateRoomRevertUnMap.pending, (state) => {
        state.revertUnMapRoom = false;
      })
      .addCase(updateRoomRevertUnMap.fulfilled, (state) => {
        state.revertUnMapRoom = true;
      })
      .addCase(updateRoomRevertUnMap.rejected, (state) => {
        state.revertUnMapRoom = false;
      })
      .addCase(getRoomsHotel.fulfilled, (state, action) => {
        state.listRoomsHotel = get(action, "payload.data");
      })
      .addCase(getRoomsHotel.rejected, (state) => {
        state.listRoomsHotel = [];
      })
      .addCase(setTarget.fulfilled, (state) => {
        state.setTarget = true;
      })
      .addCase(setTarget.rejected, (state) => {
        state.setTarget = false;
      })
      .addCase(unsetTarget.fulfilled, (state) => {
        state.unsetTarget = true;
      })
      .addCase(unsetTarget.rejected, (state) => {
        state.unsetTarget = false;
      });
  },
});

export const { resetListRoomsByTargetRoom, actionResetStatusUnMap, actionSetCurrentRecordActionRoom, actionResetCurrentRecordActionRoom, actionResetSetTarget, actionResetUnSetTarget, actionSetIsSimulate,actionSetCurrentPage, } = slice.actions;

export const selectedListRoomsByIds = (state: RootState) =>
  state.room.listRoomsByIds;

export const selectedLoadingListRoomById = (state: RootState) =>
  state.room.loadingListRoomById;

export const selectedUnmappedRoom = (state: RootState) => state.room.unmappedRoom;

export const selectedRevertUnMapRoom = (state: RootState) => state.room.revertUnMapRoom;

export const selectedCurrentRecordActionRoom = (state: RootState) => state.room.currentRecordActionRoom;

export const selectedListRoomsHotel = (state: RootState) => state.room.listRoomsHotel;

export const selectedSetTarget = (state: RootState) => state.room.setTarget;

export const selectedUnsetTarget = (state: RootState) => state.room.unsetTarget;

export const selectedIsSimulate = (state: RootState) => state.room.isSimulate;
export const selectedCurrentPage = (state: RootState) => state.room.currentPage;

export default slice.reducer;
