import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {RootState} from "./index";
import request from "../util/request";
import { get } from "lodash";

export interface IParamsGetList {
  page: number;
  size: number;
  hotelId: number;
  hotelName: string;
  nation: string;
}

const initialState = {
  listHotel: {
    content: [],
    totalElements: 0,
  },
  loadingGetListHotel: false,
  filters: {
    nation: '',
  },
  hotel: {} as any,
};

export const actionGetListHotel = createAsyncThunk(
  "hotel/actionGetListHotel",
  async (params: IParamsGetList, { rejectWithValue }) => {
    try {
      let URL = `/hotel?size=${params.size}&page=${params.page}`;

      if (params.hotelId) URL += `&hotelId=${params.hotelId}`;
      if (params.hotelName) URL += `&hotelName=${params.hotelName}`;
      if (params.nation) URL += `&nation=${params.nation}`;
    
      const response = await request({
        url: URL,
        method: "GET",
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionShowHotel = createAsyncThunk(
  "hotel/actionShowHotel",
  async (hotelId: number, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/hotel/${hotelId}`,
        method: "GET",
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: "hotel",
  initialState,
  reducers: {
    setNation(state, action) {
      state.filters.nation = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(actionGetListHotel.pending, (state) => {
        state.loadingGetListHotel = true;
      })
      .addCase(actionGetListHotel.fulfilled, (state, action) => {
        state.listHotel.content = get(action, 'payload.data.content');
        state.listHotel.totalElements = get(action, 'payload.data.totalElements');
        state.loadingGetListHotel = false;
      })
      .addCase(actionGetListHotel.rejected, (state, action) => {
        state.loadingGetListHotel = false;
      })

      // SHOW HOTEL
      .addCase(actionShowHotel.fulfilled, (state, action) => {
        state.hotel = get(action, 'payload.data');
      })
      .addCase(actionShowHotel.rejected, (state, action) => {
        state.hotel = {};
      });
  },
});

export const { setNation } = slice.actions;

export const selectedNation = (state: RootState) => state.hotel.filters.nation;

export const selectedListHotel = (state: RootState) => state.hotel.listHotel;

export const selectedLoadingGetListHotel = (state: RootState) => state.hotel.loadingGetListHotel;

export const selectedHotelDetail = (state: RootState) => state.hotel.hotel;

export default slice.reducer;
