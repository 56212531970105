import { useEffect, useState } from 'react';
import ClassifiedDimension from '../../../common/ClassifiedDimension';
import { MANDATORY_DIMENSIONS } from '../../../const/ClassifiedDimensionConst';


interface IProps {
    rules: Array<any>,
    isMandatory: boolean,
    onClick?: Function,
    isContextMenu?: boolean,
    record?: any
}

function RuleList(props: IProps) {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(props.rules as any)
    }, [props])

    return (
        <>
            {
                data.map((rule: any, index: number) => {
                    if ((props.isMandatory && MANDATORY_DIMENSIONS.includes(rule.dimension)) || (!props.isMandatory && !MANDATORY_DIMENSIONS.includes(rule.dimension))) {
                        if (rule.combinations)
                            return <ClassifiedDimension key={index} type={rule.type} nameRuleAssumption={rule.nameRuleAssumption} listCombinations={rule.combinations} dimension={rule.dimension} target={rule.target} input={rule.input} value={rule.value} id={rule.id} referenceId={rule.referenceId} isContextMenu={true} onClick={props.onClick} assumption={rule.assumption} record={props.record} />
                        return <ClassifiedDimension key={index} type={rule.type} nameRuleAssumption={rule.nameRuleAssumption} dimension={rule.dimension} target={rule.target} input={rule.input} value={rule.value} id={rule.id} referenceId={rule.referenceId} isContextMenu={true} onClick={props.onClick} assumption={rule.assumption} record={props.record} />
                    }
                })
            }
        </>
    );
}

export default RuleList;
