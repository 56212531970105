import { uniqBy } from "lodash";
import {ABBREVIATIONS, MANDATORY_DIMENSIONS} from "../const/ClassifiedDimensionConst";
import {RULE_TYPE} from "../const/Rule";

export const getRecommendTargetRooms = (room: any, targetRooms: any) => {
    const recommendationRooms = targetRooms.map((targetRoom: any) => {
      const additionalRules = targetRoom.rules.filter((rule: any) => !MANDATORY_DIMENSIONS.includes(rule.dimension));
      const unMappedRules = getRulesInFirstRulesNotInSecondRules(targetRoom.rules, room.rules);
      const mappedRules = getRulesInFirstRulesNotInSecondRules(targetRoom.rules, unMappedRules);

      // get rules in Room is Mandotary tag
      const mappedNotDuplicate = uniqBy(mappedRules, (rule: any) => rule.value);
      let rulesRoomIsMadotary = room.rules.filter((rule: any) => MANDATORY_DIMENSIONS.includes(rule.dimension));
      rulesRoomIsMadotary = uniqBy(rulesRoomIsMadotary, (rule: any) => rule.value);
      return {
        id: targetRoom.id,
        name: targetRoom.name,
        additionalRules: additionalRules,
        unMappedRules: unMappedRules,
        mappedRules: mappedRules,
        percentage: mappedRules.length ? (mappedRules.length / (mappedRules.length + unMappedRules.length)) : 0,
        invertPercentage: mappedNotDuplicate.length ? (mappedNotDuplicate.length / rulesRoomIsMadotary.length) : 0,
      }
    }).sort((a: any, b: any) => {
      if (b.mappedRules.length - a.mappedRules.length !== 0)
        return b.mappedRules.length - a.mappedRules.length
      else return b.percentage - a.percentage
    })
    return recommendationRooms;
  }

export const getRulesInFirstRulesNotInSecondRules = (rules: any, targetRules: any) => {
    return rules.filter((rule: any) => MANDATORY_DIMENSIONS.includes(rule.dimension) && targetRules.find((targetRule: any) => targetRule.referenceId === rule.referenceId) === undefined)
}

export function format(price: number) {
    const formatter = new Intl.NumberFormat('ko-KR', {
        style: 'currency',
        currency: 'KRW',
    })
    return formatter.format(price)
}

export const getClassNameSetTarget = (record: any) => {
  let classNameForRoom = '';
  if (record.status === 3) {
    classNameForRoom += ' alert-warning';
  }
  return classNameForRoom;
}

export function renderTooltipMatchingRatio(record: any) {
  return <>
    <span>Percentage: {(record.percentage * 100).toFixed(2) + '%'}</span><br />
    <span>Invert Percentage: {(record.invertPercentage * 100).toFixed(2) + '%'}</span>
  </>
}

export function findRuleAssumption(ruleAll: any, rules: any) {
  return rules.map((rule: any) => {
    let nameRuleAssumption = '';
    if (rule.type === RULE_TYPE.ASSUMPTION) {
      const ruleIds = rule.input.split('-');
      for (const ruleId of ruleIds) {
        const findRule = ruleAll.find((rule: any) => rule.referenceId == ruleId);
        nameRuleAssumption += ' ' + ABBREVIATIONS[findRule.dimension] + '_' + findRule.value;
      }
      nameRuleAssumption = nameRuleAssumption.trim();
      return {
        ...rule,
        nameRuleAssumption,
      };
    }
    return rule;
  })
}

export function getNoneProcessedTokens(rules: Array<any>, roomName: string) {
  rules.forEach((rule: any) => {
    const removeStr = `\\b${rule.input}\\b`;
    const regex =  new RegExp(removeStr,'g'); // correct way
    return roomName = roomName.replaceAll(regex, "")
  })
  const noneProcessedRoomName = roomName.replace(/  +/g, ' ').trim()
  return noneProcessedRoomName.length > 0 ? noneProcessedRoomName.split(" ") : []
}