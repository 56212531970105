import { DeleteTwoTone, FileAddTwoTone } from "@ant-design/icons";
import { Dropdown, Menu, notification, Tag } from "antd";
import { ABBREVIATIONS, RULE_TARGET } from "../const/ClassifiedDimensionConst";
import { useAppDispatch, useAppSelector } from "../store/index";

import { deleteRule } from "../store/ruleSlice";
import { selectConnectionId } from '../store/authSlice';
import { selectedStatusIsProcessing } from "../store/engineResultSlice";
import { actionSetCurrentRecordAction } from "../store/targetRoomSlice";
import { actionSetCurrentRecordActionRoom } from "../store/roomSlice";
import { nanoid } from "@reduxjs/toolkit";
import { RULE_TYPE } from "../const/Rule";


interface IProps {
  onClick?: Function;
  // handleAddAssumtionRule?: Function;
  key?: number;
  id?: number;
  target: number;
  value: string;
  input: string;
  dimension: string;
  delete?: boolean;
  onDelete?: Function;
  listCombinations?: Array<string>;
  isContextMenu?: boolean;
  record?: any,
  assumption?: boolean,
  type?: number,
  referenceId?: any,
  nameRuleAssumption?: any,
}

function ClassifiedDimension(props: IProps) {
  const dispatch = useAppDispatch();
  const connectionId = useAppSelector(selectConnectionId);
  let tagColor = props.target === RULE_TARGET.GENERAL_RULE ? "green" : "blue";
  tagColor = props.assumption ? 'orange' : tagColor;
  const dimensionValColor = props.dimension === "IGNORE" ? "red" : "";
  const isProcessing = useAppSelector(selectedStatusIsProcessing);
  const abbreviate = (input: string) => {
    if (ABBREVIATIONS.hasOwnProperty(input)) {
      return ABBREVIATIONS[input];
    }
    return input;
  };

  function handleDeleteRule() {
    if (isProcessing) {
      notification.warn({
        message: "Please complete the mapping process before doing other tasks deleting rule",
        placement: "top",
      });
      return;
    }
    if (tagColor === "green") {
      notification.warn({
        message: "Not allowed to delete the GENERAL RULE",
        placement: "top",
      });
      return;
    }

    const confirm = window.confirm(`Are you sure to delete rule ${abbreviate(props.dimension)}_${props.value} ?`);

    if (props.id && confirm) {
      if (props.record.hasOwnProperty('targetRoomId')) {
        dispatch(actionSetCurrentRecordActionRoom(props.record));
      } else {
        dispatch(actionSetCurrentRecordAction(props.record));
      }
      dispatch(
        deleteRule({
          ruleId: props.id,
          connectionId: connectionId,
        })
      );
    }
  }

  function handleClick(event: any) {
    const { key } = event;
    if (key === 'delete') {
      handleDeleteRule()
    } else if (key === 'add_assumption_input') {
      if (props.onClick) {
        if (props.listCombinations?.length) {
          const inputs = props.listCombinations.map((combination: any) => ABBREVIATIONS[combination.dimension] + '_' + combination.value);
          props.onClick({
            input: inputs,
            referenceId: props.referenceId,
            type: RULE_TYPE.ASSUMPTION,
          });
        } else {
          props.onClick({
            input: [ABBREVIATIONS[props.dimension] + '_' + props.value],
            referenceId: props.referenceId,
            type: RULE_TYPE.ASSUMPTION,
          });
        }

        if (props.record.hasOwnProperty('targetRoomId')) {
          dispatch(actionSetCurrentRecordActionRoom(props.record));
        } else {
          dispatch(actionSetCurrentRecordAction(props.record));
        }
      }
      
    }
  }

  const itemsMenu = [
    {
      key: 'add_assumption_input',
      icon: <FileAddTwoTone />,
      label: 'Add Assumption Input'
    },
    {
      key: 'delete',
      icon: <DeleteTwoTone />,
      label: 'Delete'
    }
  ]

  const menu = (
    <Menu items={props.type == RULE_TYPE.ASSUMPTION ? itemsMenu.filter(item => item.key !== 'add_assumption_input') : itemsMenu } onClick={handleClick} />
  );

  return (
    <Dropdown overlay={menu} trigger={["contextMenu"]}>
      <div key={props.key ? props.key : nanoid()} style={{ display: "inline-block" }}>
        <Tag color={tagColor} closable={props.delete ?? false} onClose={() => props.onDelete ? props.onDelete(props.target, props.dimension, props.value) : null} onClick={() => {
          if (props.onClick && props.type !== RULE_TYPE.ASSUMPTION) {
            props.onClick({
              input: [props.input],
              referenceId: props.referenceId,
              type: RULE_TYPE.NORMAL,
            })
            if (props.record.hasOwnProperty('targetRoomId')) {
              dispatch(actionSetCurrentRecordActionRoom(props.record));
            } else {
              dispatch(actionSetCurrentRecordAction(props.record));
            }
          }
        }}>
          <p style={{ textAlign: "center", color: "black", marginBottom: "2px" }}>{props.type == RULE_TYPE.ASSUMPTION ? props.nameRuleAssumption : props.input}</p>
          {
            props.listCombinations ? props.listCombinations.map((ruleVal: any) =>
              <Tag style={{ marginRight: 0 }} color={dimensionValColor} key={ruleVal.id ? ruleVal.id : nanoid()}>{ruleVal.dimension !== 'IGNORE' ? abbreviate(ruleVal.dimension) + '_' + ruleVal.value : 'IGNORE'}</Tag>)
              : <Tag style={{ marginRight: 0 }} color={dimensionValColor}>{props.dimension !== 'IGNORE' ? abbreviate(props.dimension) + "_" + props.value : 'IGNORE'}</Tag>
          }
        </Tag>
      </div>
    </Dropdown>
  );
}

export function ConditionShowDeleteTag(props: IProps) {
  const { isContextMenu, assumption } = props;
  let tagColor = props.target === RULE_TARGET.GENERAL_RULE ? "green" : "blue";
  tagColor = assumption ? 'orange' : tagColor;
  const dimensionValColor = props.dimension === 'IGNORE' ? "red" : ""
  const abbreviate = (input: string) => {
    if (ABBREVIATIONS.hasOwnProperty(input)) {
      return ABBREVIATIONS[input];
    } return input;
  }
  if (!isContextMenu) {
    return (
      <div style={{ display: "inline-block" }}>
        <Tag color={tagColor} closable={props.delete ?? false} onClose={() => props.onDelete ? props.onDelete(props.target, props.dimension, props.value) : null} onClick={() => { if (props.onClick && props.type !== RULE_TYPE.ASSUMPTION) {
          props.onClick({
            input: [props.input],
            referenceId: props.referenceId,
            type: RULE_TYPE.NORMAL,
          })
        } }}>
          <p style={{ textAlign: "center", color: "black", marginBottom: "2px" }}>{props.type == RULE_TYPE.ASSUMPTION ? props.nameRuleAssumption : props.input}</p>
          {
            props.listCombinations ? props.listCombinations.map((ruleVal: any) =>
              <Tag style={{ marginRight: 0 }} color={dimensionValColor} key={ruleVal.id ? ruleVal.id : nanoid()}>{ruleVal.dimension !== 'IGNORE' ? abbreviate(ruleVal.dimension) + '_' + ruleVal.value : 'IGNORE'}</Tag>)
              : <Tag style={{ marginRight: 0 }} color={dimensionValColor}>{props.dimension !== 'IGNORE' ? abbreviate(props.dimension) + "_" + props.value : 'IGNORE'}</Tag>
          }
        </Tag>
      </div>
    )
  }
  return <ClassifiedDimension {...props} />;
}

export default ConditionShowDeleteTag;
