import { Button, Dropdown, Layout, Menu, Select } from "antd";
import {
  LogoutOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "antd/lib/layout/layout";
import { useAppDispatch } from "../store/index";
import { actionLogout } from "../store/authSlice";

import logoImage from '../image/logo.png';
import userImage from '../image/user.png';
import { NATIONS } from "../constants/app";
import React from "react";

import vn from '../image/vn.png';
import th from '../image/thailand.png';
import kr from '../image/korea.png';
import jp from '../image/japan.png';
import { setNation } from "../store/hotelSlice";

const { Content } = Layout;

interface DefaultLayoutProps {
  children: JSX.Element;
}

export default function DefaultLayout(props: DefaultLayoutProps) {
  const { children } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const showNationFilter = pathname === '/';

  function handleClick(event: any) {
    const { key } = event;
    if(key === 'infoUser') {
      navigate("/profile");
    } else if(key === 'logout') {
      dispatch(actionLogout());
    }
  }

  const menu = (
    <Menu items={[
      {
        key: 'infoUser',
        icon: <UserOutlined />,
        label: 'Account'
      },
      {
        key: 'logout',
        icon: <LogoutOutlined />,
        label: 'Logout'
      }
    ]} onClick={handleClick} />
  );
  function toggleCollapsed() {
    setCollapsed(!collapsed);
  }
  function getNation(nation: string) {
    let src = '';
    if (nation === 'kr') {
      src = kr;
    } else if (nation === 'vn') {
      src = vn;
    } else if (nation === 'jp') {
      src = jp;
    } else if (nation === 'th') {
      src = th;
    }
    return src;
  }

  function handleChange(value: any) {
    dispatch(setNation(value));
  }
  return (
    <Layout>
      <Header className="layout-page-header">
        <div className="logo" style={{ width: collapsed ? 80 : 250 }} onClick={() => navigate('/')}>
          <img
            src={logoImage}
            alt=""
            style={{ marginRight: collapsed ? "2px" : "20px" }}
          />
        </div>
        <div className="layout-page-header-main">
          <div onClick={toggleCollapsed}>
          </div>
          <div className="actions">
           
            {
              showNationFilter && <Select
                defaultValue={''}
                placeholder="Filter Nation"
                style={{ width: 90, marginRight: 10 }}
                onChange={handleChange}
              >
                <Select.Option
                    key={''}
                    value={''}
                    label={
                      <React.Fragment>
                        Nation
                      </React.Fragment>
                    }
                  >
                    Nation
                </Select.Option>
                {NATIONS.map(nation => (
                  <Select.Option
                    key={nation.value + nation.label}
                    value={nation.value}
                    label={
                      <React.Fragment>
                        {nation.label}
                      </React.Fragment>
                    }
                  >
                    <>
                      <img src={getNation(nation.value)} alt="" width={30} /> 
                    </>
                  </Select.Option>
                ))}
              </Select>
            }
             <Button onClick={() => navigate('/target-room-management/insertTargetRoom')} style={{ marginRight: 10, paddingTop: 1 }} icon={<UploadOutlined />}>Upload TargetRoom</Button>
            <Dropdown overlay={menu}>
              <span className="user-action">
                <img src={userImage} className="user-avator" alt="avator" />
              </span>
            </Dropdown>
          </div>
        </div>
      </Header>
      <Layout>
        <Layout>
          <Content
            style={{
              padding: 12,
              margin: 0,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
