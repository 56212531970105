import { DIMENSIONS_SCORE } from "../const/ClassifiedDimensionConst"

const sortFollowAlphaBeta = (combinations: Array<any>) => {
    return combinations.sort((previous: any, current: any) => {
        return compareTwoRule(previous, current)
    })
}

export const compareTwoRule = (previous: any, current: any) => {
    if (DIMENSIONS_SCORE[previous.dimension] !== DIMENSIONS_SCORE[current.dimension])
        return DIMENSIONS_SCORE[current.dimension] - DIMENSIONS_SCORE[previous.dimension]
    else if (previous.dimension !== current.dimension)
        return previous.dimension.localeCompare(current.dimension)
    return previous.value.localeCompare(current.value)
}

export const getSortedRulesFollowAlphaBetaWithCombinations = (rules: Array<any>) => {
    rules.forEach((rule) => rule.combinations = undefined)
    
    const returnRules = rules.length < 2 ? rules : rules.reduce(
        (previousResult: any, currentRule: any) => {
            const lastRule = Array.isArray(previousResult) ? previousResult[previousResult.length - 1] : previousResult;
            if (lastRule.input === currentRule.input && lastRule.target === currentRule.target) {
                const combinations = lastRule.combinations ? [...lastRule.combinations, { dimension: currentRule.dimension, value: currentRule.value }] :
                    [{ dimension: lastRule.dimension, value: lastRule.value }, { dimension: currentRule.dimension, value: currentRule.value }]
                lastRule.combinations = sortFollowAlphaBeta(combinations)
                lastRule.dimension = combinations[0].dimension
                lastRule.value = combinations[0].value
                return previousResult
            } else {
                if (Array.isArray(previousResult))
                    return [...previousResult, currentRule]
                else return [previousResult, currentRule]
            }
        });
    return Array.isArray(returnRules) ? sortFollowAlphaBeta(returnRules) : [returnRules]
}