import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import auth from "./authSlice";

import hotel from "./hotelSlice";
import targetRoom from "./targetRoomSlice";
import room from "./roomSlice";
import rule from "./ruleSlice";
import engineResult from "./engineResultSlice";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["userLogin", "isLogin"],
  version: 1,
};



const reducers = {
  auth: persistReducer(authPersistConfig, auth),
  targetRoom,
  room,
  hotel,
  rule,
  engineResult,
};

const rootReducer = combineReducers(reducers);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
